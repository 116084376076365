.welcome-bar {
    height: 82px;
    width: 90vw;
    top: 90px;
    right: 60px;
    left: 120px;
    align-items: center;
    position: fixed;
    background-color: white;
}
.backg {
    border: 500px solid #f4f5fa;
}
.texts {
    text-indent: 100px;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    display: inline-block;
    font-style: normal;
}
.textse {
    text-indent: 100px;
    font-size: 15px;
    line-height: 42px;
    letter-spacing: 0em;
}
.col {
    -webkit-text-fill-color: blue;
}

/* //styleName: subHeading 2/Bold;
text-align: left; */
