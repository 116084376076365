@media only screen and (max-width: 767px) {
    .cards {
        /* border: 1px solid black; */
        height: 500px;
        width: 1000vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 600px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cards {
        /* border: 1px solid black; */
        height: 500px;
        width: 1000vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 600px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }
}

@media only screen and (min-width: 992px) {
    .cards {
        /* border: 1px solid black; */
        height: 500px;
        width: 1000vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 600px;
        align-items: center;
        position: fixed;
        background-color: #ffffff;
        /* z-index: 0; */
    }
}
.ds-profile {
    height: 200px;
    width: 370px;
    left: 80px;
    background-color: #ffffff;
}
.profile-pic {
    margin: 12px 25px;
    width: 50px;
}
