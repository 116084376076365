.Client-Order {
    margin-left: 0.2em;
    margin-right: 0.4em;
    background-color: white;
    padding: 1em;
}

.predef-row {
    padding-bottom: 0rem;
}

.predef-option {
    padding: 0.8rem;
    border: 1px solid transparent;
    border-bottom: 1px solid #afb0b3;
}
.predef-option:hover {
    border-bottom: 1px solid #5167dc;
    color: #5167dc;
}

.create-order {
    border-radius: 5px;
}

.client-order-table-grid {
    margin-top: 2em;
    margin-bottom: 10px;
    height: 365px;
}
.employee-table {
    margin-top: 2em;
    margin-bottom: 10px;
    height: 365px;
}

.employee-table {
    margin-top: 2em;
    margin-bottom: 10px;
    height: 365px;
}

.table-header {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
}
.table-column-name {
    /* border-radius: 2px; */
    /* background-color: rgba(233, 237, 240, 1); */
    /* border: 1px solid black; */
    width: 180px;
}

.transfer-status {
    border-radius: 5px;
    text-align: center;
    background-color: rgba(38, 198, 220, 0.4);
    color: hsl(195, 71%, 44%);
}

.done-status {
    border-radius: 5px;
    text-align: center;
    background-color: rgba(53, 182, 102, 0.4);
    color: #35b666;
}
.issue-raised-status {
    border-radius: 5px;
    text-align: center;
    background-color: rgba(44, 74, 208, 0.4);
    color: rgb(111, 111, 195);
}
.cancelled-status {
    border-radius: 5px;
    text-align: center;
    background-color: rgba(125, 125, 125, 0.4);
    color: gray;
}
.processing-status {
    border-radius: 5px;
    text-align: center;
    background-color: rgba(208, 175, 44, 0.4);
    color: orange;
}
.overdue-status {
    border-radius: 5px;
    text-align: center;
    background-color: rgba(208, 52, 44, 0.4);
    color: red;
}
.pagination-footer {
    margin-top: 1em;
    /* margin-left: 2em; */
    /* padding: 0px 1em; */
    color: #646464;
}

.grid-order-card {
    border: 1px solid black;
}
