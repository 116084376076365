.nav-sidebar {
    background-color: white;
    display: inline-block;
    height: 100vh;
    width: 4rem;
    position: fixed;
    border-radius: 0px 5px 5px 0px;
    z-index: 1;
}
.navbar-logo {
    background-color: #fff;
    border-radius: 0px 5px 0px 0px;
    padding: 25% 0% 5%;
}
.logo {
    width: 35%;
    margin: 25px 0% 0px 0%;
}
.navbar-element-box {
    border-radius: 10px;
    border: 3px solid transparent;
    width: 100%;
    opacity: 0.5;
    fill: gray;

    /* border: 3px solid #5570f1; */
}
.navbar-active {
    background-color: #5570f1;
    fill: white;
    opacity: 1;
}

.navbar-element-box:hover {
    background-color: #5570f1;
    opacity: 1;
    fill: white;
}
