
.pic-prof {
    top: 100px;
    left: 100px;
    position: fixed;
}
.details-prof {
    top: 370px;
    align-items: center;
    position: fixed;
}

.ds-orders {
    height: 112px;
    width: 60vw;
    top: 180px;
    left: 490px;
    align-items: overflow;
    position: fixed;
    background-color: white;
}

.notification {
    margin: 25px;
    width: 25px;
}
.header {
    margin-left: 4rem;
    padding-top: 120px;
    padding-left: 1%;
    padding-right: 2%;
}
.headerTitle {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 30px;
    color: #45464e;
}
.graphTitle {
    display: inline-block;
    font-style: normal;
    font-weight: 200;
    font-size: 0.9rem;
    line-height: 30px;
    color: #45464e;
}
.nameTitle {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 30px;
    color: #45464e;
}

.notification-panel {
    position: absolute;
    top: 50px;
    right: 250px;
    width: max-content;
    height: max-content;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: auto;
}
