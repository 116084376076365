@media only screen and (max-width: 767px) {
    .notif {
        /* border: 1px solid black; */
        height: 245px;
        width: 183vw;
        top: 440px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }
    .notifd {
        /* border: 1px solid black; */
        height: 490px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }
    .notifc {
        /* border: 1px solid black; */
        height: 240px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }

    .notifa {
        /* border: 1px solid black; */
        height: 265px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 125px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }

    .notifb {
        /* border: 1px solid black; */
        height: 220px;
        width: 183vw;
        top: 470px;
        border-radius: 0.5rem;
        left: 125px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .notif {
        /* border: 1px solid black; */
        height: 245px;
        width: 183vw;
        top: 440px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }
    .notifd {
        /* border: 1px solid black; */
        height: 490px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }
    .notifc {
        /* border: 1px solid black; */
        height: 240px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }

    .notifa {
        /* border: 1px solid black; */
        height: 265px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 125px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }

    .notifb {
        /* border: 1px solid black; */
        height: 220px;
        width: 183vw;
        top: 470px;
        border-radius: 0.5rem;
        left: 125px;
        align-items: center;
        position: absolute;
        background-color: #ffffff;
        /* z-index: 0; */
    }
}

@media only screen and (min-width: 992px) {
    .notif {
        /* border: 1px solid black; */
        height: 245px;
        width: 183vw;
        top: 440px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: fixed;
        background-color: #ffffff;
        /* z-index: 0; */
    }
    .notifd {
        /* border: 1px solid black; */
        height: 490px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: fixed;
        background-color: #ffffff;
        /* z-index: 0; */
    }
    .notifc {
        /* border: 1px solid black; */
        height: 240px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 1075px;
        align-items: center;
        position: fixed;
        background-color: #ffffff;
        /* z-index: 0; */
    }

    .notifa {
        /* border: 1px solid black; */
        height: 265px;
        width: 183vw;
        top: 190px;
        border-radius: 0.5rem;
        left: 125px;
        align-items: center;
        position: fixed;
        background-color: #ffffff;
        /* z-index: 0; */
    }

    .notifb {
        /* border: 1px solid black; */
        height: 220px;
        width: 183vw;
        top: 470px;
        border-radius: 0.5rem;
        left: 125px;
        align-items: center;
        position: fixed;
        background-color: #ffffff;
        /* z-index: 0; */
    }
}

.hhg {
    backdrop-filter: blur(8px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
