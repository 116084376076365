.plus:after {
    content: '+';
    position: absolute;
    right: 1em;
    color: #101010;
}

input:checked + .plus:after {
    content: '-';
    line-height: 0.8em;
    color: #101010;
}

.accordion__content {
    max-height: 0em;
    transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);
}
input[name='panel']:checked ~ .accordion__content {
    /* Get this as close to what height you expect */
    max-height: 50em;
}
.order-bar {
    height: 110px;
    width: 60vw;
    top: 90px;
    left: 500px;
    align-items: center;
    position: fixed;
    background-color: white;
}
.orp-bar {
    height: 110px;
    width: 20vw;
    top: 90px;
    left: 100px;
    position: fixed;
    background-color: white;
}

.acc-bar {
    height: 110px;
    width: 90vw;
    top: 220px;
    left: 100px;
    position: fixed;
}

summary {
    pointer-events: auto;
}

.clickable {
    pointer-events: auto;
    cursor: pointer;
}

.not-clickable {
    pointer-events: none;
    cursor: not-allowed;
    background: red;
}
