.Background {
    height: 100vh;
}

.logo {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}
.card {
    height: 50%;
    width: 30%;
    margin-bottom: 45%;
    margin-left: 10%;
}
.align {
    position: absolute;
    width: 10%;
    height: 30%;
    left: 20%;
    top: 38%;
    margin-bottom: 45%;
    margin-left: 10%;
}
.alignsvg {
    position: absolute;
    width: 50%;
    height: 70%;
    left: 10%;
    top: 20%;
}

.menu {
    position: absolute;

    list-style-type: none;
    margin: 5px 0;
    padding: 0;

    border: 1px solid grey;
    width: 150px;
}

.menu > li {
    margin: 0;
    background-color: white;
}

.menu > li:hover {
    background-color: lightgray;
}

.menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;

    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
    align-items: center;
}

.ggh {
    width: 64%;
    margin-left: 20%;
    margin-bottom: 10%;
}

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}
.hh {
    backdrop-filter: blur(8px);
}
