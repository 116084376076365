.blurCircleBackground {
    height: 100vh;
}
.Website-Outreach-Pool {
    margin-left: 4.3em;
    margin-right: 0.4em;
    background-color: white;
    padding: 0.2em 1em;
}
.Outreached-Card {
    border-radius: 5px;
    border-top: 0.2px solid #5970e6;
    border-right: 0.2px solid #5970e6;
    border-left: 0.22px solid #5970e6;
    border-bottom: 4px solid #5970e6;
    background-color: white;
    display: inline-block;
    max-width: 220px;
    padding: 1.25% 2%;
}
.outreach-circle {
    width: 0.9rem;
    height: 0.9rem;
    /* background-color: #3bbf69; */
    background-image: linear-gradient(to right, #5459ae, #5167dc);
    border-radius: 50%;
    display: inline-block;
}
.Done-Card {
    border-radius: 5px;
    border-top: 0.2px solid #3bbf69;
    border-right: 0.2px solid #3bbf69;
    border-left: 0.22px solid #3bbf69;
    border-bottom: 4px solid #3bbf69;
    background-color: white;
    display: inline-block;
    max-width: 200px;
    padding: 1.25% 2%;
}
.done-circle {
    width: 0.9rem;
    height: 0.9rem;
    /* background-color: #3bbf69; */
    background-image: linear-gradient(to right, #409354, #35b666);
    border-radius: 50%;
    display: inline-block;
}
.card-title {
    display: inline-block;
    color: #646464;
    font-weight: bold;
    font-size: 1.25rem;
}
.card-open {
    display: inline-block;
}
.Done-Card .card-open .chevron-right {
    width: 1rem;
    color: #35b666;
}
.Outreached-Card .card-open .chevron-right {
    width: 1rem;
    color: #5167dc;
}
.card-value {
    border: 1px dashed rgba(100, 100, 100, 0.8);
    margin: 10px 0px;
    padding: 4%;
    padding-right: 1%;
    width: 70%;
}
.icon {
    border-right: 1px solid #646464;
    display: inline-block;
    padding-right: 15%;
}
.value {
    display: inline-block;
    padding-left: 15%;
}
.Done-Card .card-value .value {
    color: #35b666;
}
.Outreached-Card .card-value .value {
    color: #5167dc;
}
.Outreached-Card:hover,
.Done-Card:hover {
    cursor: pointer;
}
.filter-Icon {
    display: inline-block;
}
.plus-Icon {
    display: inline-block;
}
.options {
    /* display: inline-block; */
    /* margin-left: 2%; */
    width: 100%;
}
.filter-options {
    display: inline-block;
    padding: 0.5rem 0;
    font-weight: 600;
    text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

.website-outreach-pool-table {
    /* margin-right: 1em; */
    margin-top: 2em;
    margin-bottom: 10px;
    height: 365px;
    /* box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.05); */
}

.table-header {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
}
/* table thead {
    position: sticky;
    top: 0;
} */

.table-column-name {
    min-width: 120px;
    /* border-radius: 2px; */
    /* background-color: rgba(233, 237, 240, 1); */
    /* border: 1px solid black; */
}

.edit-option {
    color: transparent;
}
.pagination-footer {
    margin-top: 1em;
    /* margin-left: 2em; */
    /* padding: 0px 1em; */
    color: #646464;
}
/* .ipp {
    border: 1px solid black;
    width: 20em;
    margin-bottom: 10px;
} */
.site-url {
    color: blue;
}
.poc,
.site-contact-details {
    color: blue;
}
.done-status {
    /* border: 1px solid black; */
    border-radius: 5px;
    text-align: center;
    background-color: rgba(53, 182, 102, 0.4);
    color: #35b666;
}

.nego-status {
    border-radius: 5px;
    text-align: center;
    background-color: rgba(208, 52, 44, 0.4);
    color: red;
}

.predef-row {
    padding: 0.75rem;
    padding-bottom: 0rem;
}

.predef-option {
    padding: 0.8rem;
    border: 1px solid transparent;
    border-bottom: 1px solid #afb0b3;
}

.predef-option:hover {
    border-bottom: 1px solid #5167dc;
    color: #5167dc;
}

.input-number-disable-arrows {
    appearance: textfield;
}
