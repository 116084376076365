.Background {
    height: 100vh;
}
.sidebar {
    position: absolute;
    width: 232px;
    height: 545px;
    left: 555px;
    top: 231px;
}
.logo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.card {
    margin-bottom: -45%;
    margin-left: 5%;
}
.align {
    position: absolute;
    width: 10%;
    height: 30%;
}
.alignsvg {
    position: absolute;
    width: 30%;
    height: 58%;
    left: 21%;
    top: 22.6%;
}
.cardReset {
    height: 40%;
    width: 30%;
    margin-bottom: -45%;
    margin-left: 0%;
}
.alignimg {
    position: absolute;
    width: 16%;
    height: 40%;
    left: 28.5%;
    top: 37%;
}
